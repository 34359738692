const LoadingPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '100px', color: 'white', fontSize: '20px'}}>
            <p><b>Loading....</b></p>
            <img style={{ height: 30 }}src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca.gif"></img>
        </div>
    );
};

export default LoadingPage;
